<template>
  <div class="wrapper">
    <menu-ver type="company" name="Company" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
          :style="{ backgroundColor: colorCompany }"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="users && users.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("mCompany.users.name") }}</th>
            <th scope="col">{{ $t("mCompany.users.user") }}</th>
            <th scope="col">{{ $t("mCompany.users.email") }}</th>
            <th scope="col" style="text-align: center">
              {{ $t("mCompany.users.courses") }}
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in users" :key="item.usu_id">
            <td>{{ item.usu_nombre }}</td>
            <td>{{ item.usu_usuario }}</td>
            <td>{{ item.usu_email }}</td>
            <td style="text-align: center">{{ item.num_cursos }}</td>
            <td style="min-width: 115pt">
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                :data-title="$t(`menu.borrar`)"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit ms-2"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                :style="{ backgroundColor: colorCompany }"
                :data-title="$t(`menu.editar`)"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                class="btn button buttonEdit ms-2"
                @click="getActions(3, item)"
                data-bs-toggle="modal"
                data-bs-target="#coursesModal"
                :style="{ backgroundColor: colorCompany }"
                :data-title="$t(`menu.seecourses`)"
              >
                <i class="fas fa-list"></i>
              </button>
              <!-- <button
                class="btn button buttonEdit"
                @click="seeReports()"
                :style="{ backgroundColor: colorCompany }"
                :data-title="$t(`mCompany.users.report`)"
              >
                <i
                  style="padding-right: 2px; padding-left: 2px"
                  class="fas fa-file"
                ></i>
              </button> -->
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-user">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mCompany.users.addTitle", { name: `${userId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName"
                v-model.trim="user.usu_nombre"
                :placeholder="$t(`mCompany.users.name`) + ' *'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.user1`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="user"
                v-model.trim="user.usu_usuario"
                :placeholder="$t(`mCompany.users.user1`) + '*'"
                maxlength="20"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                v-model.trim="user.usu_clave"
                :placeholder="$t(`mCompany.users.password`) + '*'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="user.usu_clave"
                :placeholder="$t(`mCompany.users.password`) + '*'"
                maxlength="20"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="email"
                class="form-control"
                id="email"
                v-model.trim="user.usu_email"
                :placeholder="$t(`mCompany.users.email`) + '*'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`mCompany.users.dni`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="user.usu_dni"
                :placeholder="$t(`mCompany.users.dni`)"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.pertenece`)
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="user.usu_detalle"
                :placeholder="$t(`mCompany.users.pertenece`)"
                maxlength="255"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addUser"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mCompany.users.deleteTitle", { name: `${userId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("mCompany.users.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteUser"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-user">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mCompany.users.editTitle", { name: `${userId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormNameEdit"
                v-model.trim="user.usu_nombre"
                :placeholder="$t(`mCompany.users.name`) + '*'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label style="display: block" class="form-label">{{
                $t(`mCompany.users.state`) + " *"
              }}</label>
              <input
                v-if="user.usu_bloqueado !== 0"
                style="text-transform: uppercase"
                type="text"
                class="form-control input-state"
                id="state"
                value="Bloqueado"
                disabled
              />
              <input
                v-else
                style="text-transform: uppercase"
                type="text"
                class="form-control input-state"
                id="state"
                value="No Bloqueado"
                disabled
              />
              <button
                v-if="user.usu_bloqueado !== 0"
                @click="unlockUser"
                class="btn btn-outline-secondary btn-state"
                type="button"
              >
                {{ $t("maintenance.superusers.lock") }}
              </button>
              <button
                v-else
                @click="lockUser"
                class="btn btn-outline-secondary btn-state"
                type="button"
              >
                {{ $t("maintenance.companies.lock") }}
              </button>
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.user`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="userEdit"
                v-model.trim="user.usu_usuario"
                :placeholder="$t(`mCompany.users.user`) + '*'"
                maxlength="20"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                v-model.trim="user.usu_clave"
                :placeholder="$t(`mCompany.users.password`) + '*'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="user.usu_clave"
                :placeholder="$t(`mCompany.users.password`) + '*'"
                maxlength="20"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="email"
                class="form-control"
                id="emailEdit"
                v-model.trim="user.usu_email"
                :placeholder="$t(`mCompany.users.email`) + '*'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`mCompany.users.dni`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="user.usu_dni"
                :placeholder="$t(`mCompany.users.dni`)"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mCompany.users.pertenece`)
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="user.usu_detalle"
                :placeholder="$t(`mCompany.users.pertenece`)"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t("mUser.courses.envioAviso") }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model="envio_aviso"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: 0px;
                  margin-right: 5px;
                "
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editUser"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal courses -->
  <div
    class="modal fade"
    id="coursesModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" style="max-width: 1250px">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mCompany.users.listcourses") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h4>{{ $t("mCompany.users.assigned") }}</h4>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t("mCompany.users.name") }}</th>
                <th scope="col">{{ $t("mCompany.users.state") }}</th>
                <th scope="col">{{ $t("mCompany.users.theme") }}</th>
                <th scope="col">{{ $t("mCompany.users.time") }}</th>
                <th scope="col">{{ $t("mUser.courses.calif") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in inCourse" :key="item.usu_id">
                <td>{{ item.curso_titulo }}</td>
                <td>
                  <p v-if="item.curso_estado === 1">
                    {{ $t("mCompany.users.incourse") }}
                  </p>
                  <p v-else-if="item.curso_estado === 2">
                    {{ $t("mCompany.users.finished") }}
                  </p>
                </td>
                <td>{{ item.tema_titulo }}</td>
                <td>{{ item.tiempo_consumido }}</td>
                <td>{{ item.curso_calificacion }}</td>
                <td>
                  <button
                    v-if="item.curso_estado === 1"
                    class="btn button buttonEdit ms-2"
                    @click="deassignCourse(item)"
                    :style="{ backgroundColor: colorCompany }"
                    :data-title="$t(`menu.deasigncourse`)"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                  <button
                    class="btn button buttonEdit ms-2"
                    @click="sendAlert(item)"
                    :style="{ backgroundColor: colorCompany }"
                    :data-title="$t(`menu.alert`)"
                  >
                    <i
                      style="padding-right: 5px; padding-left: 5px"
                      class="fas fa-exclamation"
                    ></i>
                  </button>
                  <button
                    class="btn button buttonEdit ms-2"
                    @click="getMessages(item)"
                    data-bs-toggle="modal"
                    data-bs-target="#messagesModal"
                    :style="{ backgroundColor: colorCompany }"
                    :data-title="$t(`menu.messages`)"
                  >
                    <i class="fas fa-envelope"></i>
                  </button>
                  <button
                    class="btn button buttonEdit"
                    @click="getWorks(item)"
                    data-bs-toggle="modal"
                    data-bs-target="#worksModal"
                    :style="{ backgroundColor: colorCompany }"
                    :data-title="$t(`menu.works`)"
                  >
                    <i
                      style="padding-right: 2px; padding-left: 2px"
                      class="fas fa-file-word"
                    ></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <h4>{{ $t("mCompany.users.noAssigned") }}</h4>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t("mCompany.users.name") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in courses" :key="item.curso_id">
                <td>{{ item.curso_titulo }}</td>
                <td>
                  <button
                    class="btn button buttonEdit"
                    @click="assignCourse(item)"
                    :style="{ backgroundColor: colorCompany }"
                    :data-title="$t(`menu.asigncourse`)"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn button buttonCreate"
            data-bs-dismiss="modal"
            style="width: 50%"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Mensajes con Alumno -->
  <div
    class="modal fade"
    id="messagesModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mCompany.users.listMessages") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="returnToCourses"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <textarea
              v-model.trim="user_message"
              class="form-control"
              rows="7"
              style="text-transform: uppercase"
              maxlength="255"
            />
          </div>
          <div style="justify-content: center">
            <button
              type="submit"
              class="btn-light btnCrud buttonCreate"
              @click="sendMessage()"
              :style="{ backgroundColor: colorCompany }"
              style="width: 100%"
            >
              {{ $t("actions.send") }}
            </button>
          </div>
        </div>
        <div class="mb-3 modal-body">
          <h4>{{ $t("mCompany.users.messages") }}</h4>
          <table class="table" v-if="listMessages && listMessages.length > 0">
            <thead>
              <tr>
                <th scope="col">{{ $t("phases.date") }}</th>
                <th scope="col">{{ $t("mUser.course.mensaje") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in listMessages" :key="item.mecu_id">
                <td>{{ item.mecu_fecha }}</td>
                <td>{{ item.mecu_texto }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn button buttonCreate"
            @click="returnToCourses()"
            style="width: 50%"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Trabados del Alumno -->
  <div
    class="modal fade"
    id="worksModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mCompany.users.worksFromStudent") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="returnToCoursesFromWorks"
          ></button>
        </div>
        <div class="modal-body">
          <table class="table" v-if="listWork && listWork.length > 0">
            <thead>
              <tr>
                <th scope="col">{{ $t("mUser.course.name") }}</th>
                <th scope="col">{{ $t("mUser.courses.notaDelAlumno") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in listWork" :key="item.enbloque_id">
                <td style="vertical-align: middle">{{ item.bloque_titulo }}</td>
                <td style="vertical-align: middle">
                  <input type="number" v-model="item.enbloque_puntos" style="width: 83px; margin-bottom: 0px; height: 25px;"/>
                </td>
                <td>
                  <a
                    :href="
                      servidor +
                      'ver_trabajo_prof.asp?enbloque_id=' +
                      item.enbloque_id
                    "
                    target="_blank"
                    class="btn button buttonEdit ms-2"
                    :style="{ backgroundColor: colorCompany }"
                    ><i class="fas fa-download"></i
                  ></a>
                  <button
                    class="btn button buttonEdit"
                    @click="saveNote(item.enbloque_id, item.enbloque_puntos)"
                    :style="{ backgroundColor: colorCompany }"
                    :data-title="$t(`actions.save`)"
                  >
                    <i
                      style="padding-right: 2px; padding-left: 2px"
                      class="fas fa-save"
                    ></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else>{{ $t("mCompany.users.noworks") }}</p>
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            type="button"
            class="btn button buttonCreate"
            @click="returnToCoursesFromWorks()"
            style="width: 50%"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 3">
                {{ $t("errors.4") }}
              </div>
              <div class="modal-body" v-else-if="action === 4">
                {{ $t("errors.8") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="(action === 3 || action === 4) && result !== '0'"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      users: [
        // {
        //   usu_id: 1,
        //   usu_nombre: "David",
        // },
      ],
      user: {},
      courses: [],
      inCourse: [
        // {
        //   curso_id: 1,
        //   tema_titulo: "aa",
        // },
      ],
      userId: "",
      result: "",
      action: 0,
      id: "",
      formError: false,
      loading: false,
      visPas: true,
      haveSignature: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      messageError: "",
      envio_aviso: false,
      temaActual: "",
      user_message: "",
      message_curso: "",
      listMessages: [],
      listWork: [
        // {
        //   enbloque_id: 1,
        //   bloque_titulo: "aa",
        //   enbloque_puntos: 2
        // },
        // {
        //   enbloque_id: 2,
        //   bloque_titulo: "bb",
        //   enbloque_puntos: 10
        // },
      ],
    };
  },
  created() {
    this.getUsers();
    this.changeBreadCrumb(8);
    this.bigDrop();
  },
  computed: {
    ...mapState(["colorCompany", "servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getUsers() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=listado`
        );
        if (res.data) {
          this.users = res.data.data;
        }
        // console.log("USUARIOS", res);
        // console.log("USUARIOS", this.users);
      } catch (error) {
        console.error(error);
      }
    },
    async getCourses() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=cursos`
        );
        if (res & res.data) {
          this.courses = res.data.data;
        }
        // console.log("CURSOS", this.courses);
      } catch (error) {
        // console.log(error);
      }
    },
    async getInCourses(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=encurso&alumno=${id}`
        );
        if (res & res.data) {
          this.inCourses = res.data.data;
        }
        // console.log("ENCURSO", this.inCourses);
      } catch (error) {
        console.error(error);
      }
    },
    async addUser() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const user = {
            usu_nombre: this.user.usu_nombre.toUpperCase(),
            usu_clave: this.user.usu_clave,
            usu_usuario: this.user.usu_usuario.toUpperCase(),
            usu_rol: this.user.usu_rol,
            usu_email: this.user.usu_email.toUpperCase(),
            usu_dni: this.user.usu_dni,
            usu_detalle: this.user.usu_detalle.toUpperCase(),
          };
          if (this.haveSignature) {
            user.usu_firma = "1";
          } else {
            user.usu_firma = "0";
          }
          // console.log("envio", user);
          const res = await axios.post(
            `${this.servidor}api/op_emp.asp?tp=usu&ac=grabar`,
            user
          );
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async editUser() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          let user = {
            usu_nombre: this.user.usu_nombre.toUpperCase(),
            usu_clave: this.user.usu_clave,
            usu_usuario: this.user.usu_usuario.toUpperCase(),
            usu_rol: this.user.usu_rol,
            usu_email: this.user.usu_email.toUpperCase(),
            usu_id: this.user.usu_id,
            usu_dni: this.user.usu_dni,
            usu_detalle: this.user.usu_detalle.toUpperCase(),
          };
          // console.log("envio", user);
          if (this.envio_aviso) {
            user.envio_aviso = "1";
          } else {
            user.envio_aviso = "0";
          }
          if (this.haveSignature) {
            user.usu_firma = "1";
          } else {
            user.usu_firma = "0";
          }
          // console.log("envio", user);
          const res = await axios.post(
            `${this.servidor}api/op_emp.asp?tp=usu&ac=actualizar`,
            user
          );
          this.result = res.data.RESULT;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.cleanForms();
      }
    },
    async deleteUser() {
      this.result = "";
      try {
        const borrado = {
          usu_id: this.id,
        };
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.cleanForms();
    },
    async unlockUser() {
      try {
        const unlock = {
          usu_id: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=desbloquear`,
          unlock
        );
        if (res.data.RESULT === "0") {
          // this.user.usu_bloqueado = 0;
          this.action = 3;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
          this.cleanForms();
        } else {
          this.result = res.data.RESULT;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async lockUser() {
      try {
        const lock = {
          usu_id: this.id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=bloquear`,
          lock
        );
        if (res.data.RESULT === "0") {
          // this.user.usu_bloqueado = 0;
          this.action = 4;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
          this.cleanForms();
        } else {
          this.result = res.data.RESULT;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getId(event) {
      this.userId = event[0];
    },
    validateForm() {
      this.messageError = this.$t("errors.fieldOb");
      // console.log(this.user);
      if (this.user.usu_nombre === "") {
        this.formError = true;
        return false;
      }
      if (this.user.usu_clave === "") {
        this.formError = true;
        return false;
      }
      if (!this.isEmailValid()) {
        this.formError = true;
        return false;
      }
      if (this.user.usu_rol === "") {
        this.formError = true;
        return false;
      }
      if (!this.validatePassword()) {
        this.formError = true;
        this.messageError = this.$t("configuration.messageError2");
        return false;
      }
      this.messageError = "";
      return true;
    },
    cleanForms() {
      this.user = {
        usu_id: "",
        usu_nombre: "",
        usu_clave: "",
        usu_usuario: "",
        usu_email: "",
        usu_rol: "0",
        usu_dni: "",
        usu_detalle: "",
      };
      this.envio_aviso = false;
      this.loading = false;
      this.formError = false;
      this.haveSignature = false;
      this.getUsers();
      this.messageError = "";
    },
    async getActions(act, item) {
      // console.log(item);
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.id = item.usu_id;
        this.user = item;
        if (this.user.usu_firma === 1) {
          this.haveSignature = true;
        } else {
          this.haveSignature = false;
        }
        return;
      }
      if (act === 3) {
        const alumno = {
          usu_id: item.usu_id,
        };
        // console.log("alumno", alumno);
        if (alumno.usu_id !== null) {
          const res = await axios.post(
            `${this.servidor}api/op_emp.asp?tp=usu&ac=noapuntado`,
            alumno
          );
          // console.log(res);
          if (res.data) {
            this.courses = res.data.data;
          }
          const res2 = await axios.post(
            `${this.servidor}api/op_emp.asp?tp=usu&ac=apuntado`,
            alumno
          );
          if (res2.data) {
            this.inCourse = res2.data.data;
          }
        }
      }
      if (item.usu_id) {
        this.id = item.usu_id;
      }
      // console.log("id", this.id);
    },
    isEmailValid() {
      return this.user.usu_email == ""
        ? ""
        : this.reg.test(this.user.usu_email)
        ? true
        : false;
    },
    validatePassword() {
      if (this.user.usu_clave.length >= 8) {
        let mayuscula = false;
        let minuscula = false;
        let numero = false;
        let caracter_raro = false;

        for (var i = 0; i < this.user.usu_clave.length; i++) {
          if (
            this.user.usu_clave.charCodeAt(i) >= 65 &&
            this.user.usu_clave.charCodeAt(i) <= 90
          ) {
            mayuscula = true;
          } else if (
            this.user.usu_clave.charCodeAt(i) >= 97 &&
            this.user.usu_clave.charCodeAt(i) <= 122
          ) {
            minuscula = true;
          } else if (
            this.user.usu_clave.charCodeAt(i) >= 48 &&
            this.user.usu_clave.charCodeAt(i) <= 57
          ) {
            numero = true;
          } else {
            caracter_raro = true;
          }
        }
        if (
          mayuscula == true &&
          minuscula == true &&
          caracter_raro == true &&
          numero == true
        ) {
          return true;
        }
      }
      return false;
    },
    async assignCourse(course) {
      const assigncourse = {
        encurso_alumno_cd: this.id,
        encurso_curso_cd: course.curso_id,
      };
      // console.log(assigncourse);
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=asignarcurso`,
          assigncourse
        );
        // console.log(res);
        this.getUsers();
        $("#coursesModal").modal("hide");
      } catch (error) {
        console.error(error);
      }
    },
    async deassignCourse(course) {
      const assigncourse = {
        encurso_alumno_cd: this.id,
        encurso_curso_cd: course.curso_id,
      };
      // console.log(assigncourse);
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=deasignarcurso`,
          assigncourse
        );
        // console.log(res);
        this.getUsers();
        $("#coursesModal").modal("hide");
      } catch (error) {
        console.error(error);
      }
    },
    async sendAlert(item) {
      // console.log("item", item);
      try {
        // console.log("ID: ", this.id);
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=usu&ac=sendalert&id=${this.id}&curso=${item.curso_id}`
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getMessages(item) {
      $("#coursesModal").modal("hide");
      // console.log("item", item);
      // console.log("id", this.id);
      this.message_curso = item;
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=listadoMensajes&curso=${item.curso_id}&alumno=${this.id}`
        );
        // console.log("recMensajes", res);
        if (res.data) {
          this.listMessages = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async sendMessage() {
      const message = {
        mecu_texto: this.user_message.toUpperCase(),
        mecu_curso_cd: this.message_curso.curso_id,
        mecu_alumno_cd: this.id,
        empresa_id: "",
      };
      try {
        // console.log("mensaje", message);
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=grabar`,
          message
        );
        if (res.data) {
          this.result = res.data.RESULT;
        } else {
          this.result = "1";
        }
      } catch (error) {
        console.error(error);
      }
      $("#messagesModal").modal("hide");
      this.user_message = "";
      $("#responseModal").modal("show");
    },
    returnToCourses() {
      $("#messagesModal").modal("hide");
      this.getActions(3, this.message_curso);
      $("#coursesModal").modal("show");
    },
    seeReports() {
      alert("En construcción");
    },
    async getWorks(item) {
      $("#coursesModal").modal("hide");
      // console.log("item", item);
      // console.log("id", this.id);
      this.message_curso = item;
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=trabajos_empresa&curso=${item.curso_id}&alumno=${this.id}`
        );
        // console.log("recWork", res);
        if (res.data) {
          this.listWork = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    returnToCoursesFromWorks() {
      $("#worksModal").modal("hide");
      this.getActions(3, this.message_curso);
      $("#coursesModal").modal("show");
    },
    async saveNote(id, nota) {
      // console.log("ID: " + id + ", NOTA: " + nota)
      const note = {
        enbloque_id: id,
        enbloque_puntos: parseFloat(nota)
      }
      // console.log(note);
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=profesor&ac=poner_nota`,
          note
        );
        // console.log("recNote", res);        
      } catch (error) {
        console.error(error);
      }
      this.returnToCoursesFromWorks();
    }
  },
};
</script>

<style scoped>
.field-icon {
  float: right;
  margin-top: -71px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  bottom: 2em;
  left: -5em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
}

[data-title] {
  position: relative;
}
</style>